import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserPackageModel} from "./user-packages.interface";
import {RestApiService} from "../../../../../core/services/rest-api/rest-api.service";
import {UserPackagesMapper} from "./mappers/user-packages.mapper";

@Injectable()
export class UserPackagesRepository {
  constructor(
    private restApiService: RestApiService,
    private mapper: UserPackagesMapper
  ) {
  }

  public findAll(): Observable<UserPackageModel[]> {
    return this.restApiService.get('v2/orders/packages', {}).pipe(
      map(this.mapper.mapCollection)
    );
  }
}
