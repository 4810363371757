import {Injectable} from '@angular/core';
import {UserPackageModel} from "../user-packages.interface";

@Injectable()
export class UserPackagesMapper {
  public static mapModel(data: any): UserPackageModel {
    return {
      id: data.id,
      role_id: data.role_id,
      expire: data.expire,
      day_left: data.day_left,
      starts_in: data.starts_in,
      created_at: data.created_at,
      name: data.name,
      description: data.description
    };
  }

  public mapCollection(data: any): UserPackageModel[] {
    return data.map(resp => {
      return UserPackagesMapper.mapModel(resp);
    });
  }
}
