import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-wiwn-lite',
  templateUrl: './wiwn-lite.component.html',
  styleUrls: ['./wiwn-lite.component.scss']
})
export class WiwnLiteComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
