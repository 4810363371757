import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {NotificationModel} from "../services/notification.interface";

@Injectable({
  providedIn: 'root'
})
export class NotificationStoreService {
  notifications$: Observable<NotificationModel[]>;
  notificationsSubject: BehaviorSubject<NotificationModel[]>;

  constructor() {
    this.notificationsSubject = new BehaviorSubject<NotificationModel[]>(null);
    this.notifications$ = this.notificationsSubject.asObservable();
  }
}
