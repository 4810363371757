import {Injectable} from '@angular/core';
import {BonusModel, MainPackagesCollection, OrderModel, PackageModel, PackagesCollection, PaymentStatusModel} from '../packages.interface';

@Injectable()
export class MainPackagesMapper {
  public static mapModel(data: any): PackageModel {
    return {
      id: data.id,
      name: data.name,
      title: data.title,
      slug: data.slug,
      description: data.description,
      price: data.price,
      netPrice: data.netPrice,
      tax: data.tax,
      pricePerMonth: data.profit !== 30 ? Math.ceil(data.price / data.profit * 30) : Math.ceil(data.price),
      currency: data.currency,
      profit: data.profit,
      profit_unit: data.profit_unit,
      fullPrice: data.fullPrice,
      promotion: {
        code: data?.promotion?.code ? data?.promotion?.code : null,
        description: data?.promotion?.description ? data?.promotion?.description : null,
        profit: data?.promotion?.profit ? data?.promotion?.profit : null,
        unit: data?.promotion?.unit ? data?.promotion?.unit : null,
        expireInMinutes: data?.promotion?.expireInMinutes ? data?.promotion?.expireInMinutes : null
      }
    };
  }

  public mapCollection(data: any): MainPackagesCollection {
    const packageCollection: MainPackagesCollection = {
      group: []
    };

    Object.keys(data).map((index) => {

      packageCollection.group.push({
          name: index,
          packages: data[index].map((packageData) => {
            return MainPackagesMapper.mapModel(packageData);
          })
        }
      );
    });

    return packageCollection;
  }
}
