import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {UserSettingsModel} from "../services/user-settings/user-settings.interface";

@Injectable({
  providedIn: 'root'
})
export class UserSettingsStoreService {

  userSettings$: Observable<UserSettingsModel>;
  userSettingsSubject: BehaviorSubject<UserSettingsModel>;

  constructor() {
    this.userSettingsSubject = new BehaviorSubject<UserSettingsModel>(null);
    this.userSettings$ = this.userSettingsSubject.asObservable();
  }

}
