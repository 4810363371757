import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestApiService} from '../../../../../core/services/rest-api/rest-api.service';
import {PackagesMapper} from './mappers/packages.mapper';
import {
  BonusModel, MainPackagesCollection,
  OrderModel, PackageBonusInput, PackageModel,
  PackageOrderInput, PackageOrderWithoutLoginInput,
  PackagesCollection,
  PaymentStatusInput,
  PaymentStatusModel
} from './packages.interface';
import {AuthenticationService} from '../../../../../core/services/auth/authentication.service';
import {MainPackagesMapper} from './mappers/mainPackages.mapper';

@Injectable()
export class PackagesRepository {
  constructor(
    private restApiService: RestApiService,
    private authenticationService: AuthenticationService,
    private mapper: PackagesMapper,
    private mainMapper: MainPackagesMapper,
  ) {
  }

  public findAll(): Observable<PackagesCollection> {
    return this.restApiService.get('v2/packages', {}).pipe(
      map(this.mapper.mapCollection)
    );
  }

  public findAllMain(): Observable<MainPackagesCollection> {
    return this.restApiService.get('v2/packages/main', {}).pipe(
      map(this.mainMapper.mapCollection)
    );
  }

  public findOne(packageId: string, bonusBode: string = null, amount: number = 1): Observable<PackageModel> {
    let endpoint = 'v2/package/' + packageId;

    if (null !== bonusBode) {
      endpoint = endpoint + '/' + bonusBode;
    }

    if (amount > 1) {
      endpoint = `v2/package/amount/${packageId}/${amount}`;
    }

    return this.restApiService.get(endpoint, {}).pipe(
      map(PackagesMapper.mapModel)
    );
  }

  public findOneForLogged(packageId: string, amount: number = 1): Observable<PackageModel> {
    let endpoint = 'v2/packageWithBonus/' + packageId;

    if (amount > 1) {
      endpoint = `v2/package/amount/${packageId}/${amount}`;
    }

    return this.restApiService.get(endpoint, {}).pipe(
      map(PackagesMapper.mapModel)
    );
  }

  public order(params: PackageOrderInput): Observable<OrderModel> {
    return this.restApiService.post('v2/packages/order', {
      packageId: params.packageId,
      code: params.code,
      invoiceContractor: params.invoiceContractor
    }).pipe(
      map(this.mapper.mapOrder)
    );
  }

  public orderForLogged(params: PackageOrderInput): Observable<OrderModel> {
    return this.restApiService.post('v2/packages/orderWithBonus', {
      packageId: params.packageId,
      invoiceContractor: params.invoiceContractor,
      code: params.code,
      amount: params.amount ?? 1,
    }).pipe(
      map(this.mapper.mapOrder)
    );
  }

  public orderWithoutRegister(params: PackageOrderWithoutLoginInput): Observable<OrderModel> {
    return this.restApiService.post('v2/order', {
      packageId: params.packageId,
      code: params.code,
      invoiceContractor: params.invoiceContractor,
      email: params.registerData.email,
      username: params.registerData.name,
      newsletter: params.registerData.newsletter,
      captcha: params.registerData.captcha,
      regulationAcceptance: params.registerData.regulationAcceptance,
      amount: params.amount ?? 1,
    }).pipe(
      map(this.mapper.mapOrder)
    );
  }

  public status(params: PaymentStatusInput): Observable<PaymentStatusModel> {
    return this.restApiService.post('v2/order/status', {
      transactionToken: params.transactionToken,
    }).pipe(
      map(this.mapper.mapToPaymentStatusModel)
    );
  }

  public bonus(params: PackageBonusInput): Observable<BonusModel> {
    return this.restApiService.get('v2/bonus', {
      code: params.code,
      packageId: params.packageId
    }).pipe(
      map(this.mapper.mapToBonusModel)
    );
  }
}
