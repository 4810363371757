import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestApiService} from '../../../core/services/rest-api/rest-api.service';
import {NotificationModel} from './notification.interface';
import {NotificationMapper} from './mappers/notification.mapper';

@Injectable()
export class NotificationRepository {
  constructor(
    private restApiService: RestApiService
  ) {
  }

  public findAll(): Observable<NotificationModel[]> {
    return this.restApiService.get('v2/notification', {}).pipe(
      map(NotificationMapper.mapCollection)
    );
  }

  public save(notification: NotificationModel): Observable<any> {
    return this.restApiService.post('v2/notification', {
      id: notification.id,
      marketId: notification.marketId,
      filterId: notification.filterId,
      active: notification.active,
      allDay: notification.allDay,
      sendAfter: notification.sendAfter !== null ?  {
        hour: notification.sendAfter.hour,
        minute: notification.sendAfter.minute,
      } : null,
      sendBefore: notification.sendBefore !== null ? {
        hour: notification.sendBefore.hour,
        minute: notification.sendBefore.minute,
      } : null,
      frequency: notification.frequency,
      type: notification.realEstateType
    });
  }

  public delete(notification: NotificationModel): Observable<any> {
    return this.restApiService.delete('v2/notification', {
      id: notification.id
    });
  }
}
