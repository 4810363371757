<div class="bg-primary shadow border-0 modal-header {{ modalContent?.titleClass ? modalContent?.titleClass : 'bg-darker'}}"
     *ngIf="modalContent?.title">
    <h3 class="modal-title text-center w-100 text-white pr-2 text-ellipsis" translate [innerHtml]="modalContent?.title"></h3>
    <i class="far fa-times-circle text-white" *ngIf="modalContent.showCloseButton === true || undefined === modalContent.showCloseButton" (click)="close($event)"></i>
</div>
<div class="modal-body ">
    <div [innerHtml]="modalContent?.text" *ngIf="modalContent?.text"></div>

    <div class="text-light" *ngIf="modalContent?.tpl">
        <ng-container *ngTemplateOutlet="modalContent?.tpl"></ng-container>
    </div>

    <content #content></content>
</div>
<div class="modal-footer border-0" *ngIf="buttons?.confirmButtonName || buttons?.closeButtonName">
    <button type="{{ buttons?.closeButtonType ? buttons?.closeButtonType : 'button' }}"
            class="{{ buttons?.closeButtonClass ? buttons?.closeButtonClass : 'btn btn-block btn-danger waves-effect m-2' }}"
            (click)="close($event)" *ngIf="buttons?.closeButtonName">
        {{ buttons?.closeButtonName ? buttons?.closeButtonName : ('Close' | translate) }}
    </button>
    <button type="{{ buttons?.confirmButtonType ? buttons?.confirmButtonType : 'button' }}"
            class="{{ buttons?.confirmButtonClass ? buttons?.confirmButtonClass : 'btn btn-block btn-primary m-2' }}"
            (click)="confirm($event)" *ngIf="buttons?.confirmButtonName">
        {{ buttons?.confirmButtonName ? buttons?.confirmButtonName : ('Confirm' | translate) }}
    </button>
</div>
