import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsFormComponent} from './components/notifications-form/notifications-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NotificationService} from './services/notification.service';
import {NotificationMapper} from './services/mappers/notification.mapper';
import {NotificationRepository} from './services/notification.repository';
import {SingleNotificationFormComponent} from './components/single-notification-form/single-notification-form.component';
import {RouterModule} from '@angular/router';
import {SimpleNotificationFormComponent} from './components/simple-notification-form/simple-notification-form.component';


@NgModule({
  declarations: [NotificationsFormComponent, SingleNotificationFormComponent, SimpleNotificationFormComponent],
  exports: [
    NotificationsFormComponent,
    SingleNotificationFormComponent,
    SimpleNotificationFormComponent
  ],
  providers: [
    NotificationService,
    NotificationMapper,
    NotificationRepository
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    MatSnackBarModule,
    RouterModule
  ]
})
export class NotificationsModule {
}
