import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {UserPackagesRepository} from "./user-packages.repository";
import {UserPackageModel} from "./user-packages.interface";

@Injectable({
  providedIn: 'root'
})
export class UserPackagesService {

  constructor(
    private repository: UserPackagesRepository
  ) { }

  public getAll(): Observable<UserPackageModel[]> {
    return this.repository.findAll();
  }
}
