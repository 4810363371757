import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {UserSettingsModel} from "./user-settings.interface";
import {UserSettingsRepository} from "./user-settings.repository";
import {first, map, shareReplay, switchMap} from "rxjs/operators";
import {UserSettingsMapper} from "./mappers/user-settings.mapper";

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    private repository: UserSettingsRepository,
    private mapper: UserSettingsMapper
  ) {
  }

  public getAll(): Observable<UserSettingsModel> {
    return this.repository.findAll();
  }

  public update(market: UserSettingsModel, password: string = null): Observable<any> {
    return of(market).pipe(
      map(market => this.mapper.mapToEditableModel(market, password)),
      switchMap(marketInput => this.repository.update(marketInput).pipe(first())),
      shareReplay()
    );
  }

  public updateLiteVersion(liteVersion: boolean = false): Observable<any> {
    return this.repository.updateLiteVersion(liteVersion);
  }
}
