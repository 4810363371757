import {Component, OnInit} from '@angular/core';
import {StorageService} from "./modules/core/services/storage/storage.service";
import {LastVisit} from "./app.interface";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private storageService: StorageService, public translate: TranslateService) {
    translate.setDefaultLang('pl');
    translate.use('pl');
  }

  ngOnInit(): void {
    this.setLastVisitTimestamp();
  }

  private setLastVisitTimestamp() {
    let currentTimestamp = Math.floor(Date.now()/1000);
    let lastVisit = this.storageService.getSingle(LastVisit.current);

    if (null === lastVisit) {
      this.storageService.setSingle({
        key: LastVisit.current,
        value: 0
      }, localStorage);

    }

    this.storageService.setSingle({
      key: LastVisit.previous,
      value: lastVisit
    }, localStorage);

    this.storageService.setSingle({
      key: LastVisit.current,
      value: currentTimestamp
    }, localStorage);
  }
}
