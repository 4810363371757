import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TransactionModel} from "./transactions.interface";
import {TransactionsMapper} from "./mappers/transactions.mapper";
import {RestApiService} from "../../../../../core/services/rest-api/rest-api.service";

@Injectable()
export class TransactionsRepository {
  constructor(
    private restApiService: RestApiService,
    private mapper: TransactionsMapper
  ) {
  }

  public findAll(): Observable<TransactionModel[]> {
    return this.restApiService.get('v2/orders/transactions', {}).pipe(
      map(this.mapper.mapCollection)
    );
  }
}
