<header>

  <!-- Sidebar navigation -->
    <mdb-side-nav #sidenav [fixed]="true">

    <mdb-card class="testimonial-card shadow-none bg-transparent">
      <div class="box border-bottom border-dark p-2">
        <a href="#" class="box h2 d-flex text-white align-items-center justify-content-center p-0">
          <img src="assets/images/logo.png" class="h-100 p-2 mw-100">
        </a>
      </div>
    </mdb-card>

    <div>
      <ul class="collapsible">
        <li>
          <a *ngIf="acl.hasAccessToUrl('/')" [ngClass]="(radioModel == 'dashboard') ? 'active' : ''" (click)="onPageChange('/')" class="collapsible-header waves-effect ">
            <i class="fab fa-leanpub mr-2"></i> {{ 'Dashboard' | translate }}
          </a>
        </li>

        <li *ngIf="null !== (userSettingsStore.userSettings$ | async)?.data.meta.activePoll">
          <a [ngClass]="(radioModel == 'questionnaire') ? 'active' : ''" (click)="onPageChange('/questionnaire')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-comment-dots mr-2"></i> {{ 'Feedback' | translate }} <span class="badge badge-warning">{{ '+7 days investor' | translate }}</span>
          </a>
        </li>
      </ul>
      <h3 class="m-3 text-white pb-1">{{ 'Leads' | translate }}</h3>
      <ul class="collapsible ml-3">
        <li>
          <a *ngIf="acl.hasAccessToUrl('/leads/sell/flat')" [ngClass]="(isSellOrRent === 'sell') ? 'active' : ''" (click)="onPageChange('leads/sell/flat')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-dollar-sign mr-2"></i> {{ ('For sell' | translate) }}
          </a>
          <a *ngIf="!acl.hasAccessToUrl('/leads/sell/flat')" (click)="onPageChange('/payments/unavailable/flat')" class="collapsible-header waves-effect text-disabled">
            <i class="fas nav-icon-width fa-dollar-sign mr-2"></i> {{ ('For sell' | translate) }}
          </a>
        </li>
        <li>
          <a *ngIf="acl.hasAccessToUrl('/leads/rent/flat')" [ngClass]="(isSellOrRent === 'rent') ? 'active' : ''" (click)="onPageChange('leads/rent/flat')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-retweet mr-2"></i> {{ ('For rent' | translate) }}
          </a>
          <a *ngIf="!acl.hasAccessToUrl('/leads/rent/flat')" (click)="onPageChange('/payments/unavailable/rent/flat')" class="collapsible-header waves-effect text-disabled">
            <i class="fas nav-icon-width fa-retweet mr-2"></i> {{ ('Flats' | translate) }}
          </a>
        </li>
        <li>
          <a *ngIf="acl.hasAccessToUrl('/auctions-flats')" [ngClass]="(radioModel == 'auctions-flats') ? 'active' : ''" (click)="onPageChange('auctions-flats')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-gavel mr-2"></i> {{ ('Bailiff auction' | translate) }}
          </a>
          <a *ngIf="!acl.hasAccessToUrl('/auctions-flats')" (click)="onPageChange('/payments/unavailable/auctions-flats')" class="collapsible-header waves-effect text-disabled">
            <i class="fas nav-icon-width fa-gavel mr-2"></i> {{ ('Bailiff auction' | translate) }}
          </a>
        </li>
      </ul>
      <h3 class="m-3 text-white pb-1">{{ 'Watched' | translate }}</h3>
      <ul class="collapsible ml-3">
        <li>
          <a *ngIf="acl.hasAccessToUrl('/settings/notifications')" [ngClass]="(radioModel == 'notifications') ? 'active' : ''" (click)="onPageChange('/settings/notifications')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-bell mr-2"></i> {{ 'Notifications' | translate }} <i class="fas nav-icon-width fa-circle text-warning" *ngIf="(notification.getAll() | async)?.length === 0"></i>
          </a>
          <a *ngIf="!acl.hasAccessToUrl('/settings/notifications')" (click)="onPageChange('/payments/unavailable/notifications')" class="collapsible-header waves-effect text-disabled">
            <i class="fas nav-icon-width fa-bell mr-2"></i> {{ 'Notifications' | translate }}
          </a>
        </li>
      </ul>
      <h3 class="m-3 text-white pb-1">{{ 'Tools' | translate }}</h3>
      <ul class="collapsible ml-3">
        <li>
          <a *ngIf="acl.hasAccessToUrl('/market-research')" [ngClass]="(radioModel == 'market-research') ? 'active' : ''" (click)="onPageChange('market-research')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-star mr-2"></i> {{ 'Market research' | translate}} <span class="badge badge-danger" *ngIf="getCountLastAddedLeadsToMarketResearch().length > 0 ">{{ getCountLastAddedLeadsToMarketResearch().length }}</span>
          </a>
          <a *ngIf="!acl.hasAccessToUrl('/market-research')" (click)="onPageChange('/payments/unavailable/market-research')" class="collapsible-header waves-effect text-disabled">
            <i class="fas nav-icon-width fa-star mr-2"></i> {{ 'Market research' | translate}}
          </a>
        </li>
        <li>
          <a *ngIf="acl.hasAccessToUrl('/stats')" [ngClass]="(radioModel == 'stats') ? 'active' : ''" (click)="onPageChange('stats')" class="collapsible-header waves-effect">
            <i class="fas nav-icon-width fa-chart-bar mr-2"></i> {{ 'Statistics' | translate}}
          </a>
          <a *ngIf="!acl.hasAccessToUrl('/stats')" (click)="onPageChange('/payments/unavailable/stats')" class="collapsible-header waves-effect text-disabled">
            <i class="fas nav-icon-width fa-chart-bar mr-2"></i> {{ 'Statistics' | translate}}
          </a>
        </li>
      </ul>

      <h3 class="m-3 text-white pb-1">{{ 'Payments' | translate }}</h3>
      <ul class="collapsible ml-3">
        <li>
          <a [ngClass]="(radioModel == 'payments') ? 'active' : ''" (click)="onPageChange('/payments')" class="collapsible-header waves-effect font-weight-bold text-secondary">
            <i class="fas nav-icon-width fa-key mr-2"></i> {{ 'Buy premium' | translate }}
          </a>
        </li>
        <li>
          <a [ngClass]="(radioModel == 'packages') ? 'active' : ''" (click)="onPageChange('/payments/packages')" class="collapsible-header waves-effect ">
            <i class="fa fa-cubes mr-2"></i> {{ 'Your packages' | translate }}
          </a>
        </li>
        <li>
          <a [ngClass]="(radioModel == 'transactions') ? 'active' : ''" (click)="onPageChange('/payments/transactions')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-dollar-sign mr-2"></i> {{ 'Your transactions' | translate}}
          </a>
        </li>
      </ul>
      <h3 class="m-3 text-white pb-1">{{ 'General' | translate }}</h3>
      <ul class="collapsible ml-3">
        <li>
          <a href="{{ environment.landing.url }}/blog/" target="_blank" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-blog mr-2"></i> {{ 'Blog' | translate}}
          </a>
        </li>
        <li>
          <a [ngClass]="(radioModel == 'contact') ? 'active' : ''" (click)="onPageChange('/contact')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-file-signature mr-2"></i> {{ 'Contact' | translate}}
          </a>
        </li>
      </ul>

      <h3 class="m-3 text-white d-block d-lg-none pb-1">{{ 'Your account' | translate }}</h3>
      <ul class="collapsible ml-3 d-block d-lg-none">
        <li>
          <a [ngClass]="(radioModel == 'settings') ? 'active' : ''" (click)="onPageChange('/settings')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-cog mr-2"></i> {{ 'Settings' | translate }}
          </a>
        </li>
        <li>
          <a [ngClass]="(radioModel == 'questionnaire') ? 'active' : ''" (click)="onPageChange('/questionnaire')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-comment-dots mr-2"></i> {{ 'Feedback' | translate }} <span class="badge badge-warning">{{ '+7 days investor' | translate }}</span>
          </a>
        </li>
        <li>
          <a [ngClass]="(radioModel == 'logout') ? 'active' : ''" (click)="onPageChange('/settings/logout')" class="collapsible-header waves-effect ">
            <i class="fas nav-icon-width fa-sign-out-alt mr-2"></i> {{ 'Logout' | translate }}
          </a>
        </li>
      </ul>
    </div>
  </mdb-side-nav>

  <div class="mobile-menu-mask" *ngIf="mobileMenuShow"  (click)="hideMobileMenu()"></div>
  <!--/. Sidebar navigation -->

  <!-- Navbar -->
  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav bg-nav"
              [containerInside]="false">


    <navlinks class="mr-auto d-block d-lg-none">
      <!-- SideNav slide-out button -->
      <div class="float-right">
        <a (click)="showSideNav()" class="button-collapse text-primary"><mdb-icon fas icon="bars"></mdb-icon></a>
      </div>
      <!--/. SideNav slide-out button -->
    </navlinks>
    <navlinks class="mr-0 px-3 text-primary mr-lg-auto ml-auto ml-lg-0">
      <span *ngIf="radioModel == 'dashboard'"><i class="fab fa-leanpub mr-2"></i>{{'Dashboard' | translate}}</span>
      <span *ngIf="radioModel == 'sell-flat'"><i class="fas nav-icon-width fa-building mr-2"></i>{{'Flats leads for sell' | translate}}</span>
      <span *ngIf="radioModel == 'rent-flat'"><i class="fas nav-icon-width fa-building mr-2"></i>{{'Flats leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'sell-house'"><i class="fas nav-icon-width fa-home mr-2"></i>{{'Houses leads for sell' | translate}}</span>
      <span *ngIf="radioModel == 'rent-house'"><i class="fas nav-icon-width fa-home mr-2"></i>{{'Houses leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'sell-land'"><i class="fas nav-icon-width fa-map-marked mr-2"></i>{{'Lands leads for sell' | translate}}</span>
      <span *ngIf="radioModel == 'rent-land'"><i class="fas nav-icon-width fa-map-marked mr-2"></i>{{'Lands leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'sell-warehouse'"><i class="fas nav-icon-width fa-warehouse mr-2"></i>{{'Warehouses leads for sell' | translate}}</span>
      <span *ngIf="radioModel == 'rent-warehouse'"><i class="fas nav-icon-width fa-warehouse mr-2"></i>{{'Warehouses leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'sell-garage'"><i class="fas nav-icon-width fa-parking mr-2"></i>{{'Garages leads for sell' | translate}}</span>
      <span *ngIf="radioModel == 'rent-garage'"><i class="fas nav-icon-width fa-parking mr-2"></i>{{'Garages leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'sell-premises'"><i class="fas nav-icon-width fa-city mr-2"></i>{{'Premises leads for sell' | translate}}</span>
      <span *ngIf="radioModel == 'rent-premises'"><i class="fas nav-icon-width fa-city mr-2"></i>{{'Premises leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'rent-room'"><i class="fas nav-icon-width fa-door-open mr-2"></i>{{'Rooms leads for rent' | translate}}</span>

      <span *ngIf="radioModel == 'market-research'"><i class="fas nav-icon-width fa-star mr-2"></i>{{'Market research' | translate}}</span>
      <span *ngIf="radioModel == 'stats'"><i class="fas nav-icon-width fa-chart-bar mr-2"></i>{{'Statistics' | translate}}</span>
      <span *ngIf="radioModel == 'your-contacts'"><i class="fas nav-icon-width fa-handshake mr-2"></i>{{'Your Contacts' | translate}}</span>
      <span *ngIf="radioModel == 'payments'"><i class="fas nav-icon-width fa-key mr-2"></i>{{'Payments' | translate}}</span>
      <span *ngIf="radioModel == 'packages'"><i class="fas nav-icon-width fa-cubes mr-2"></i>{{'Your packages' | translate}}</span>
      <span *ngIf="radioModel == 'transactions'"><i class="fas nav-icon-width fa-dollar-sign mr-2"></i>{{'Your transactions' | translate}}</span>
      <span *ngIf="radioModel == 'settings'"><i class="fas nav-icon-width fa-cog mr-2"></i>{{'Settings' | translate}}</span>
      <span *ngIf="radioModel == 'notifications'"><i class="fas nav-icon-width fa-bell mr-2"></i>{{'Notifications' | translate}}</span>
      <span *ngIf="radioModel == 'questionnaire'"><i class="fas nav-icon-width fa-comment-dots mr-2"></i>{{'Feedback' | translate}}</span>
      <span *ngIf="radioModel == 'watched'"><i class="fas nav-icon-width fa-bell mr-2"></i>{{'New leads' | translate}}</span>
      <span *ngIf="radioModel == 'contact'"><i class="fas nav-icon-width fa-file-signature mr-2"></i>{{'Contact' | translate}}</span>
      <span *ngIf="radioModel == 'auctions-flats'"><i class="fas nav-icon-width fa-gavel mr-2"></i>{{'Bailiff auction' | translate}}</span>

      <ul class="navbar-nav ml-auto d-none d-lg-block">
        <!-- Dropdown -->
        <li class="nav-item dropdown" dropdown>
          <span class="mr-3">{{ (authStore.user$ | async)?.email }}</span>
          <mdb-icon fas icon="user" dropdownToggle class="ml-auto"></mdb-icon>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-menu-right dropdown-primary" role="menu">
            <a class="dropdown-item"
               (click)="onPageChange('/settings')">
              <i class="fas nav-icon-width fa-cog mr-2"></i> {{ 'Settings' | translate }}
            </a>
            <a class="dropdown-item"
               (click)="onPageChange('/questionnaire')">
              <i class="fas nav-icon-width fa-comment-dots mr-2"></i> {{ 'Feedback' | translate }}
            </a>
            <a class="dropdown-item"
               (click)="onPageChange('/settings/logout')">
              <i class="fas nav-icon-width fa-sign-out-alt mr-2"></i> {{ 'Logout' | translate }}
            </a>
          </div>
        </li>

      </ul>

    </navlinks>

  </mdb-navbar>
  <!--/. Navbar -->

</header>
