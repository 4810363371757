import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable()
export class SubscriptionsService {
    private subscriptions: { [key: string]: Subscription } = {};

    constructor() {
    }

    find(key: string): Subscription {
        if (this.exit(key)) {
            return this.subscriptions[key];
        }
        return null;
    }

    exit(key: string): boolean {
        if (typeof this.subscriptions[key] === 'object') {
            return true;
        }
        return false;
    }

    create(key: string, value: Subscription) {
        if (this.find(key)) {
            this.find(key).unsubscribe();
            delete this.subscriptions[key];
        }
        this.subscriptions[key] = value;
    }

    remove(key?: string) {
        if (key) {
            if (this.find(key)) {
                this.find(key).unsubscribe();
            }
            return;
        }
        Object.keys(this.subscriptions).forEach(subscription => {
            if (this.find(subscription)) {
                this.find(subscription).unsubscribe();
            }
        });
    }
}
