import {
  AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, TemplateRef, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ModalContent, ModalContentButtons, ModalContentCallbacks, ModalInterface} from '../service/modal.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Component({
  templateUrl: './modal-content.component.html'
})
export class ModalContentComponent implements OnInit, AfterViewInit {

  @Input() modalContent?: ModalContent;
  @Input() contentComponent?: any;
  @Input() buttons?: ModalContentButtons;
  @Input() callbacks?: ModalContentCallbacks;
  @Input() parameters?: any;

  @ViewChild('content', {read: ViewContainerRef}) content: any;
  modalInjectComponentInstance: ModalInterface;

  constructor(public activeModal: NgbActiveModal,
              private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.init();
  }

  ngOnInit(): void {
    if (this.contentComponent) {
      this.createComponent();
    }
  }

  ngAfterViewInit(): void {
    if (this.contentComponent && this.modalInjectComponentInstance) {
      this.modalInjectComponentInstance.open(this.callbacks.openCallback);
    } else {
      this.callbacks.openCallback.emit(true);
    }
  }

  confirm(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.modalInjectComponentInstance) {
      this.modalInjectComponentInstance.confirm(this.callbacks.confirmCallback).pipe(
        switchMap(callback => {
          return this.callbacks.beforeClose$ ? this.callbacks.beforeClose$ : of(null);
        })
      ).subscribe(() => {
        this.activeModal.close(true);
      });
    } else {
      if (this.callbacks.confirmCallback) {
        this.callbacks.confirmCallback.emit(true);
      }
      this.activeModal.close(true);
    }
  }

  close(event: MouseEvent): void {
    event.stopPropagation();
    if (this.modalInjectComponentInstance) {
      this.modalInjectComponentInstance.close(event);
      this.activeModal.close(false);
    } else {
      if (this.callbacks.closeCallback) {
        //this.callbacks.closeCallback.emit(true);
      }
      this.activeModal.close(false);
    }
  }

  private init(): void {
    this.modalContent = {
      title: '',
      text: ''
    };
    this.callbacks = {
      openCallback: new EventEmitter<any>(),
      closeCallback: new EventEmitter<any>(),
      confirmCallback: new EventEmitter<any>(),
      beforeClose$: new Observable()
    };
  }

  private createComponent(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.contentComponent);
    this.content = this.content.createComponent(factory);
    if (this.content) {
      this.modalInjectComponentInstance = this.content.instance;
      this.modalInjectComponentInstance.parameters = this.parameters;
      this.modalInjectComponentInstance.parent = this;
      this.content.changeDetectorRef.detectChanges();
    }
  }
}
