import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TransactionsRepository} from "./transactions.repository";
import {TransactionModel} from "./transactions.interface";

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(
    private repository: TransactionsRepository
  ) { }

  public getAll(): Observable<TransactionModel[]> {
    return this.repository.findAll();
  }
}
