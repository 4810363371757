<section class="light-podkluczyk-skin body h-100">
  <div class="container-fluid">
    <div class="row no-gutter">
      <div class="col-md-12 bg-primary">
        <div class="login d-flex align-items-center py-5">

          <!-- Demo content-->
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-xl-7 mx-auto">
                <h3 class="display-4 text-center">
                  <img class="mw-100 m-auto" src="assets/images/logo-name.png">
                  <img class="mw-100 m-auto" src="assets/images/perelki.png">
                </h3>
                <p class="h2 text-white text-center pt-3">
                  Tylko dla posiadaczy kont w perełki.pl
                </p>

                <p class="h2 text-white text-center text-secondary">
                  30 dni podkluczyk.pl za darmo
                </p>
                <router-outlet name="main"></router-outlet>
              </div>
            </div>
          </div><!-- End -->

        </div>
      </div><!-- End -->

    </div>
  </div>

</section>
