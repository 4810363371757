import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AclService} from "./acl..service";
import {Observable} from "rxjs";
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class RolesGuard implements CanActivate {

  constructor(
    private router: Router,
    private acl: AclService,
    private authenticationService: AuthenticationService
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authenticationService.isAuthenticated()) {
      return false;
    }

    if (this.acl.hasAccessToUrl(state.url)) {
      return true;
    }

    this.router.navigate(['/payments'], {replaceUrl: true});
    return false;
  }

}
