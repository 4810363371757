<section class="light-podkluczyk-skin body h-100">
  <div class="container-fluid">
    <div class="row no-gutter">
      <div class="col-md-12 bg-primary">
        <div class="login d-flex align-items-center py-5">

          <!-- Demo content-->
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-xl-7 mx-auto">
                <p class="h2 text-white text-center">
                  Odbierz 14 dni konta inwestor gratis!
                </p>

                <p class="h3 text-secondary text-center pt-3">
                  Załóż konto do 05.11.2023 23:59:59
                </p>

                <h3 class="display-4 text-center mb-4">
                  <img class="mw-100 m-auto w-50" src="assets/images/krowka.png">
                </h3>
                <router-outlet name="main"></router-outlet>
              </div>
            </div>
          </div><!-- End -->

        </div>
      </div><!-- End -->

    </div>
  </div>

</section>
