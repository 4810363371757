import {Injectable} from '@angular/core';
import {NotificationModel} from "../notification.interface";

@Injectable()
export class NotificationMapper {
  private static mapModel(data: any): NotificationModel {
    return {
      id: data.id,
      active: data.active,
      allDay: data.allDay,
      filterId: data.filterId,
      marketId: data.marketId,
      sendAfter: data.sendAfter !== null ? {
        hour: data.sendAfter.hour,
        minute: data.sendAfter.minute,
      } : null,
      sendBefore: data.sendBefore !== null ? {
        hour: data.sendBefore.hour,
        minute: data.sendBefore.minute,
      } : null,
      frequency: data.frequency,
      realEstateType: data.type ? data.type : null
    };
  }

  public static mapCollection(data: any): NotificationModel[] {
    return data.data.map(resp => {
      return NotificationMapper.mapModel(resp);
    });
  }
}
