import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserSettingsMapper} from "./services/user-settings/mappers/user-settings.mapper";
import {UserSettingsRepository} from "./services/user-settings/user-settings.repository";
import {UserSettingsService} from "./services/user-settings/user-settings.service";
import {UserSettingsStoreService} from "./store/user-settings-store.service";



@NgModule({
  declarations: [],
  providers: [
    UserSettingsMapper,
    UserSettingsRepository,
    UserSettingsService,
    UserSettingsStoreService
  ],
  imports: [
    CommonModule
  ]
})
export class UserSettingsModule { }
