import {EventEmitter, Injectable} from '@angular/core';

import {ModalContentComponent} from '../modal-content/modal-content.component';
import {Observable, of} from 'rxjs';
import {ModalParams} from './modal.interface';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SubscriptionsService} from "../../subscriptions/subscriptions.service";

@Injectable()
export class ModalService {
    private modalId: string;
    private modals: { [s: string]: NgbModalRef };
    private modalComponents: any;

    constructor(
        private ngb: NgbModal,
        private subscriptionsService: SubscriptionsService) {
        this.reset();
    }

    open(params: ModalParams): string {
        this.modalId = this.generateId();
        this.modals[this.modalId] = this.createModal(params);
        this.configureModal(params);
        return this.modalId;
    }

    confirm(title: string, description: string, confirmButton: string, closeButton: string, confirm?: Function) {
        const onClose = new EventEmitter();
        this.subscriptionsService.create(
            'confirm-modal-onclose',
            onClose.subscribe(resp => {
                // ...
            })
        );

        const onConfirm = new EventEmitter();
        this.subscriptionsService.create(
            'confirm-modal-onconfirm',
            onConfirm.subscribe(resp => {
                confirm();
            })
        );
        this.open({
            content: {
                title: title,
                text: description
            },
            options: {
                backdrop: 'static',
                keyboard: false
            },
            buttons: {
                confirmButtonName: confirmButton,
                closeButtonName: closeButton
            },
            callbacks: {
                confirmCallback: onConfirm,
                closeCallback: onClose
            },
            windowClass: ['modal-position', 'mobile-center', 'desktop-center']
        });
    }

    /**
     * Close current modal or modal by id
     * @param {string} modalId
     * @returns {boolean}
     */
    close(modalId?: string): boolean {
        if (typeof modalId === 'undefined') {
            modalId = this.modalId;
        }
        if (this.modals[modalId]) {
            this.modals[modalId].close();
            delete this.modals[modalId];
        }
        return false;
    }

    /**
     * Close all active modals
     */
    closeAll(): void {
        if (this.modals && this.isModalOpen()) {
            Object.keys(this.modals).forEach(modalId => {
                this.close(modalId);
            });
        }
    }

    /**
     * Check if any modal is open
     * @returns {boolean}
     */
    isModalOpen(): boolean {
        if (Object.keys(this.modals).length > 0) {
            return true;
        }
        return false;
    }

    /**
     * Create new modal based on provided parameters
     * @param {ModalParams} params
     * @returns {NgbModalRef}
     */
    private createModal(params: ModalParams): NgbModalRef {
        if (!params) {
            return;
        }
        params = this.defaultValues(params);
        return this.ngb.open(
            this.modalComponents['modalContainer'],
            Object.assign({}, {}, params.options)
        );
    }

    /**
     * Configure created modal window
     * @param {ModalParams} params
     */
    private configureModal(params: ModalParams): void {
        if (!this.modals[this.modalId] || typeof this.modals[this.modalId] !== 'object' || !params) {
            return;
        }
        this.modals[this.modalId].componentInstance.modalContent = params.content;
        this.modals[this.modalId].componentInstance.contentComponent =
            params.contentComponent ? params.contentComponent : '';
        this.modals[this.modalId].componentInstance.buttons = params.buttons;
        this.modals[this.modalId].componentInstance.callbacks = params.callbacks;
        this.modals[this.modalId].componentInstance.parameters = params.parameters;
        this.modals[this.modalId].result.then((result) => {
            if (params.callbacks.closeCallback) {
                params.callbacks.closeCallback.emit(result);
            }
            if (this.modals[this.modalId]) {
                delete this.modals[this.modalId];
            }
        }).catch((event) => {
            if (params.callbacks.closeCallback) {
                params.callbacks.closeCallback.emit(false);
            }
            if (this.modals[this.modalId]) {
                delete this.modals[this.modalId];
            }
        });
    }

    /**
     * Reset state of service
     */
    private reset(): void {
        this.modals = {};
        this.defineComponents();
    }

    /**
     * Define default values for parameters not provided in parameters
     * @param {ModalParams} params
     * @returns {ModalParams}
     */
    private defaultValues(params: ModalParams): ModalParams {
        if (!params.options.windowClass && params.windowClass && params.windowClass.length) {
            params.options.windowClass = params.windowClass.join(' ');
        }
        if (!params.callbacks) {
            params.callbacks = {
                openCallback: new EventEmitter<any>(),
                closeCallback: new EventEmitter<any>(),
                confirmCallback: new EventEmitter<any>(),
                beforeClose$: new Observable()
            };
        } else {
            params.callbacks.openCallback = params.callbacks.openCallback ?
                params.callbacks.openCallback : new EventEmitter<any>();
            params.callbacks.closeCallback = params.callbacks.closeCallback ?
                params.callbacks.closeCallback : new EventEmitter<any>();
            params.callbacks.confirmCallback = params.callbacks.confirmCallback ?
                params.callbacks.confirmCallback : new EventEmitter<any>();
            params.callbacks.beforeClose$ = params.callbacks.beforeClose$ ?
                params.callbacks.beforeClose$ : of(true);
        }
        return params;
    }

    /**
     * Define content components for modals
     */
    private defineComponents(): void {
        this.modalComponents = {
            'modalContainer': ModalContentComponent
        };
    }

    /**
     * Generate uniq ID for modal
     * @returns {string}
     */
    private generateId(): string {
        //return btoa(JSON.stringify(Math.random()));
        return <string><any>Object.keys(this.modals).length + 1;
    }
}
