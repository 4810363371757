import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestApiService} from "../../../../core/services/rest-api/rest-api.service";
import {UserSettingsMapper} from "./mappers/user-settings.mapper";
import {EditableUserSettings, UserSettingsModel} from "./user-settings.interface";

@Injectable()
export class UserSettingsRepository {
  constructor(
    private restApiService: RestApiService
  ) {
  }

  public findAll(): Observable<UserSettingsModel> {
    return this.restApiService.post('v1/community/user-settings/get', {}).pipe(
      map(UserSettingsMapper.mapModel)
    );
  }

  public update(userSettings: EditableUserSettings): Observable<any> {
    return this.restApiService.post('v1/community/user-settings/update', {
      customFields: JSON.stringify(userSettings)
    });
  }

  public updateLiteVersion(liteVersion: boolean = false): Observable<any> {
    return this.restApiService.post('v1/community/user-settings/setLiteVersion', {
      enable: liteVersion ? 'true' : 'false'
    });
  }

}
