import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-perelki',
  templateUrl: './perelki.component.html',
  styleUrls: ['./perelki.component.scss']
})
export class PerelkiComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
