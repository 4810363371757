import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-wiwn-lider',
  templateUrl: './wiwn-lider.component.html',
  styleUrls: ['./wiwn-lider.component.scss']
})
export class WiwnLiderComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
