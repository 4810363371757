import {Injectable} from '@angular/core';
import {BonusModel, OrderModel, PackageModel, PackagesCollection, PaymentStatusModel} from '../packages.interface';

@Injectable()
export class PackagesMapper {
  public static mapModel(data: any): PackageModel {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      price: data.price,
      netPrice: data.netPrice,
      tax: data.tax,
      pricePerMonth: data.profit !== 30 ? Math.ceil(data.price / data.profit * 30) : Math.ceil(data.price),
      currency: data.currency,
      profit: data.profit,
      profit_unit: data.profit_unit,
      fullPrice: data.fullPrice,
      promotion: {
        code: data?.promotion?.code ? data?.promotion?.code : null,
        description: data?.promotion?.description ? data?.promotion?.description : null,
        profit: data?.promotion?.profit ? data?.promotion?.profit : null,
        unit: data?.promotion?.unit ? data?.promotion?.unit : null,
        expireInMinutes: data?.promotion?.expireInMinutes ? data?.promotion?.expireInMinutes : null,
        expireMinutes: data?.promotion?.expireMinutes ? data?.promotion?.expireMinutes : null,
        expireDays: data?.promotion?.expireDays ? data?.promotion?.expireDays : null
      }
    };
  }

  public mapCollection(data: any): PackagesCollection {
    const packageCollection: PackagesCollection = {};
    Object.keys(data).map((index) => {
      switch (index) {
        case '30':
          packageCollection.days30 = data[index].map(data => {
            return PackagesMapper.mapModel(data);
          });
          break;
        case '90':
          packageCollection.days90 = data[index].map(data => {
            return PackagesMapper.mapModel(data);
          });
          break;
      }
    });
    return packageCollection;
  }

  public mapOrder(data: any): OrderModel {
    return {
      data: {
        url: data.data.url,
        transactionToken: data.data.transactionToken
      }
    };
  }

  public mapToPaymentStatusModel(data: any): PaymentStatusModel {
    return {
      data: {
        status: data.data.status
      }
    };
  }

  public mapToBonusModel(data: any): BonusModel {
    if (typeof data.id === 'undefined' ) {
      return null;
    }

    return {
      id: data.id,
      name: data.name,
      description: data.description,
      profit: data.profit,
      profit_unit: data.profit_unit,
      package_id: data.package_id,
      active: data.active,
      package: {
        id: data.package.id,
        name: data.package.name,
        description: data.package.description,
        price: data.package.price,
        netPrice: data.package.netPrice,
        tax: data.package.tax,
        pricePerMonth: (data.package.price / data.package.profit * 30),
        currency: data.package.currency,
        profit: data.package.profit,
        profit_unit: data.package.profit_unit,
        fullPrice: data.package.fullPrice,
        promotion: {
          description: data?.promotion?.description ? data?.promotion?.description : null,
          profit: data?.promotion?.profit ? data?.promotion?.profit : null,
          unit: data?.promotion?.unit ? data?.promotion?.unit : null
        }
      }
    };
  }
}
