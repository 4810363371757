export const environment = {
  production: false,
  api: {
    community: {
      client: '1',
      secret: 'cyylOHnDKu43Ala9fb8PdRrzMI5NZ06Q8t9SvZpH',
      key: 'community',
      baseUrl: 'https://api-community.collectestate.pl'
    },
    login: {
      google: {
        url: 'https://api-community.collectestate.pl/social/auth/google/redirect'
      }
    }
  },
  landing: {
    url: 'https://podkluczyk.pl',
    regulation: 'https://podkluczyk.pl/regulamin'
  },
  packages: {
    user: {
      default: {
        active: false,
        level: 3,
        name: 'Premium',
        slug: 'user.premium',
        maxMarkets: 3
      },
      free: {
        active: true,
        level: 1
      },
      basic: {
        active: true,
        level: 2
      },
      premium: {
        active: true,
        level: 3
      },
      investor: {
        active: true,
        level: 4
      }
    }
  },
  permissions: {
    user: {
      free: {
        name: 'Free',
        features: {
          city: '0',
          notification: 'brak',
          leads: 'brak'
        }
      },
      basic: {
        name: 'Basic',
        features: {
          city: '1',
          notification: '1 / 4h',
          leads: '20+ Źródeł ogłoszeń'
        }
      },
      premium: {
        name: 'Premium',
        features: {
          city: '3',
          notification: '1 / 1h',
          leads: '20+ Źródeł ogłoszeń'
        }
      },
      investor: {
        name: 'Investor',
        features: {
          city: 'Bez limitu',
          notification: 'Bez limitu',
          leads: '20+ Źródeł ogłoszeń'
        }
      }
    }
  },
  acl: {
    path: {
      '/': ['user.basic', 'user.premium', 'user.investor'],
      '/first-steps': ['user.basic', 'user.premium', 'user.investor'],
      '/maraton': ['user.basic', 'user.premium', 'user.investor'],
      '/welcome': ['user.basic', 'user.premium', 'user.investor'],
      '/leads/sell': ['user.basic', 'user.premium', 'user.investor'],
      '/leads/rent': ['user.basic', 'user.premium', 'user.investor'],
      '/market-research': ['user.basic', 'user.premium', 'user.investor'],
      '/statistics': ['user.premium', 'user.investor'],
      '/stats': ['user.premium', 'user.investor'],
      '/settings/notifications': ['user.basic', 'user.premium', 'user.investor'],
      '/questionnaire': ['user.free', 'user.basic', 'user.premium', 'user.investor'],
      '/leads/watched': ['user.basic', 'user.premium', 'user.investor'],
      '/contact': ['user.free', 'user.basic', 'user.premium', 'user.investor'],
      '/auctions-flats': ['user.investor'],
      '/regulation/promotion/userByDay': ['user.basic', 'user.premium', 'user.investor'],
    }
  }
};
