<section class="light-podkluczyk-skin body h-100">
  <div class="container-fluid">
    <div class="row no-gutter">
      <div class="col-md-12">
        <div class="login d-flex align-items-center py-5">

          <!-- Demo content-->
          <div class="container">
            <div class="row">
              <div class="col-lg-12 mx-auto">
                <h3 class="display-4 text-center">
                  <img class="mw-100 m-auto" src="assets/images/promocja-1000.png">
                </h3>
                <router-outlet name="main"></router-outlet>
              </div>
            </div>
          </div><!-- End -->

        </div>
      </div><!-- End -->

    </div>
  </div>

</section>
