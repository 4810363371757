import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TransactionsMapper} from './transactions/services/trensactions/mappers/transactions.mapper';
import {TransactionsService} from './transactions/services/trensactions/transactions.service';
import {TransactionsRepository} from './transactions/services/trensactions/transactions.repository';
import {UserPackagesMapper} from './user-packages/services/user-packages/mappers/user-packages.mapper';
import {UserPackagesService} from './user-packages/services/user-packages/user-packages.service';
import {UserPackagesRepository} from './user-packages/services/user-packages/user-packages.repository';
import {PackagesMapper} from './packages/services/trensactions/mappers/packages.mapper';
import {PackagesService} from './packages/services/trensactions/packages.service';
import {PackagesRepository} from './packages/services/trensactions/packages.repository';
import {MainPackagesMapper} from './packages/services/trensactions/mappers/mainPackages.mapper';



@NgModule({
  providers: [
    TransactionsMapper,
    TransactionsService,
    TransactionsRepository,

    UserPackagesMapper,
    UserPackagesService,
    UserPackagesRepository,

    PackagesMapper,
    PackagesService,
    PackagesRepository,

    MainPackagesMapper
  ],
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class OrdersModule { }
