import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, BehaviorSubject, of, throwError} from 'rxjs';
import {switchMap, map, mergeMap, catchError} from 'rxjs/operators';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User, UserCredentials} from './authentication.model';
import {StorageService} from '../storage/storage.service';
import {environment} from '../../../../../environments/environment';


@Injectable()
export class AuthenticationService {
  private firstStepsUrl = '/first-steps/filters/flat';

  public credentialsKey = 'credentials';
  private _credentials: User;
  public user: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private storageService: StorageService,
              private httpClient: HttpClient,
              private router: Router) {
    this._credentials = JSON.parse(
      this.storageService.getSingle(this.credentialsKey, sessionStorage) ||
      this.storageService.getSingle(this.credentialsKey, localStorage)
    );
    this.applyUser(this._credentials);
  }

  login(context: UserCredentials, redirect: string = '/'): Observable<any> {
    return this.httpClient.post<any>(
      environment.api.community.baseUrl + '/oauth/token', {
        grant_type: 'client_credentials',
        client_id: environment.api.community.client,
        client_secret: environment.api.community.secret
      }
    ).pipe(
      switchMap((resp) => {
        if (resp && resp.access_token) {
          const httpOptions = {
            headers: new HttpHeaders({
              Accept: 'application/json',
              Authorization: 'Bearer ' + resp.access_token
            })

          };
          return this.httpClient.post<any>(
            environment.api.community.baseUrl + '/api/v1/login', {
              email: context.email,
              password: context.password,
            }, httpOptions
          );
        }
      }),
      map(credentials => {
        const user: User = credentials;
        this.setApiAccessToken(environment.api.community.key, user.accesstoken, context.remember);
        this.applyUser(user);
        this.setCredentials(user, context.remember);

        this.router.navigate([this.firstStepsUrl], {replaceUrl: true});
        if (user.loginAttempt === 0) {
          this.router.navigate([this.firstStepsUrl], {replaceUrl: true});
        } else {
          this.router.navigate([redirect], {replaceUrl: true});
        }
        return user;
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  loginByToken(token: string, redirect: string = '/'): Observable<any> {
    return this.httpClient.post<any>(
      environment.api.community.baseUrl + '/oauth/token', {
        grant_type: 'client_credentials',
        client_id: environment.api.community.client,
        client_secret: environment.api.community.secret
      }
    ).pipe(
      switchMap((resp) => {
        if (resp && resp.access_token) {
          const httpOptions = {
            headers: new HttpHeaders({
              Accept: 'application/json',
              Authorization: 'Bearer ' + resp.access_token
            })

          };
          return this.httpClient.post<any>(
            environment.api.community.baseUrl + '/api/v1/login', {
              token
            }, httpOptions
          );
        }
      }),
      map(credentials => {
        const user: User = credentials;
        this.setApiAccessToken(environment.api.community.key, user.accesstoken, false);
        this.applyUser(user);
        this.setCredentials(user, false);
        this.router.navigate([redirect], {replaceUrl: true});
        return user;
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  activate(token: string): Observable<any> {
    return this.httpClient.post<any>(
      environment.api.community.baseUrl + '/oauth/token', {
        grant_type: 'client_credentials',
        client_id: environment.api.community.client,
        client_secret: environment.api.community.secret
      }
    ).pipe(
      switchMap(resp => {
        if (resp && resp.access_token) {
          const httpOptions = {
            headers: new HttpHeaders({
              Accept: 'application/json',
              Authorization: 'Bearer ' + resp.access_token
            })
          };
          return this.httpClient.post<any>(
            environment.api.community.baseUrl + '/api/v1/activate/' + token, [], httpOptions
          );
        }
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  register(context: UserCredentials, redirect: string = '/', fingerprint: string = ''): Observable<any> {
    return this.httpClient.post<any>(
      environment.api.community.baseUrl + '/oauth/token', {
        grant_type: 'client_credentials',
        client_id: environment.api.community.client,
        client_secret: environment.api.community.secret
      }
    ).pipe(
      switchMap((resp) => {
        if (resp && resp.access_token) {

          const httpOptions = {
            headers: new HttpHeaders({
              Accept: 'application/json',
              Authorization: 'Bearer ' + resp.access_token
            })
          };
          return this.httpClient.post<any>(
            environment.api.community.baseUrl + '/api/v1/register', {
              email: context.email,
              regulationAcceptance: context.regulationAcceptance,
              username: context.username,
              captcha: context.captcha,
              password: context.password,
              c_password: context.c_password,
              packageCode: context.packageCode,
              newsletter: context.newsletter,
              tag: context.tag ?? null,
              answer: context.answer ?? null,
              fingerprint: fingerprint ?? null,
              singleUsageCode: context.singleUsageCode ?? null,
              signupSource: context.signupSource ?? null
            }, httpOptions
          );
        }
      }),
      map(credentials => {
        return credentials;
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  applyUser(user: User): void {
    this.user.next(user);
  }

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    // Clear all refetch and refresh timers
    const killId = setTimeout(function() {
      for (let i = Number(killId); i > 0; i--) {
        clearInterval(i);
      }
    }, 1);
    return of(true);
  }

  isAuthenticated(): boolean {
    const isAuthenticated = !!this.credentials;

    if (isAuthenticated && this._credentials.roles === undefined) {
      this.logout();
    }

    return isAuthenticated;
  }

  get credentials(): User {
    return this._credentials;
  }

  setCredentials(credentials?: User, remember?: boolean) {
    this._credentials = credentials || null;
    const storage = remember ? localStorage : sessionStorage;

    this.clearLocalStorage();

    if (credentials) {
      this.storageService.setSingle({
        key: this.credentialsKey,
        value: JSON.stringify(credentials)
      }, storage);
    } else {
      this.storageService.deleteSingle(this.credentialsKey, sessionStorage);
      this.storageService.deleteSingle(this.credentialsKey, localStorage);
      this.storageService.deleteSingle(environment.api.community.key, localStorage);
    }
  }

  private clearLocalStorage(): void {
    this.storageService.deleteSingle(this.credentialsKey, localStorage);
  }

  private setApiAccessToken(key: string, accessToken: string, remember?: boolean) {
    const storage = remember ? localStorage : sessionStorage;
    this.storageService.deleteSingle(key, storage);
    this.storageService.setSingle({
      key,
      value: accessToken
    }, storage);
  }

  public forgotPassword(context: UserCredentials, redirect: string = '/'): Observable<any> {
    return this.httpClient.post<any>(
      environment.api.community.baseUrl + '/oauth/token', {
        grant_type: 'client_credentials',
        client_id: environment.api.community.client,
        client_secret: environment.api.community.secret
      }
    ).pipe(
      switchMap(resp => {
        if (resp && resp.access_token) {
          const httpOptions = {
            headers: new HttpHeaders({
              Accept: 'application/json',
              Authorization: 'Bearer ' + resp.access_token
            })
          };
          return this.httpClient.post<any>(
            environment.api.community.baseUrl + '/api/v1/forgotPassword', {
              email: context.email
            }, httpOptions
          );
        }
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  public resetPassword(context: UserCredentials, redirect: string = '/'): Observable<any> {
    return this.httpClient.post<any>(
      environment.api.community.baseUrl + '/oauth/token', {
        grant_type: 'client_credentials',
        client_id: environment.api.community.client,
        client_secret: environment.api.community.secret
      }
    ).pipe(
      switchMap(resp => {
        if (resp && resp.access_token) {
          const httpOptions = {
            headers: new HttpHeaders({
              Accept: 'application/json',
              Authorization: 'Bearer ' + resp.access_token
            })
          };
          return this.httpClient.post<any>(
            environment.api.community.baseUrl + '/api/v1/resetPassword', {
              email: context.email,
              password: context.password,
              password_confirmation: context.c_password,
              token: context.token
            }, httpOptions
          );
        }
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  public isUserExists(email: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({}),
      params: {
        email
      }
    };
    return this.httpClient.get<any>(
      environment.api.community.baseUrl + '/api/v2/user/isUserExists', httpOptions
    ).pipe(
      switchMap(resp => {
        return of(resp.data.userExists);
      }),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  hasRequiredRoles(requiredRoles: string[]): boolean {
    let hasAccess = false;
    if (this.credentials === null) {
      hasAccess = false;
    } else if (requiredRoles.length === 0) {
      hasAccess = true;
    } else {
      requiredRoles.forEach((requiredRole: string) => {
        if (this.user.getValue().roles.includes(requiredRole)) {
          hasAccess = true;
        }
      });
    }
    return hasAccess;
  }
}
