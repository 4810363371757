import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../authentication.model";
import {AuthenticationService} from "../authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {

  user$: Observable<User>;
  userSubject: BehaviorSubject<User>;

  constructor(private authenticationService: AuthenticationService) {
    this.userSubject = new BehaviorSubject<User>(
      authenticationService.credentials
    );
    this.user$ = this.userSubject.asObservable();
  }
}
