import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './navigation/navigation.component';
import {TranslateModule} from '@ngx-translate/core';
import {
  AccordionModule,
  CardsModule, DropdownModule,
  IconsModule,
  NavbarModule,
  SidenavModule,
  WavesModule
} from 'ng-uikit-pro-standard';
import {StorageService} from './services/storage/storage.service';
import {AuthenticationGuard} from './services/auth/authentication.guard';
import {AuthenticationService} from './services/auth/authentication.service';
import {StorageRepository} from './services/storage/storage.repository';
import {SubscriptionsService} from './services/subscriptions/subscriptions.service';
import {ModalContentComponent} from './services/modal/modal-content/modal-content.component';
import {ModalService} from './services/modal/service/modal.service';
import {RouterModule} from '@angular/router';
import {AclService} from './services/auth/acl/acl..service';
import {RolesGuard} from './services/auth/acl/roles.guard';
import {NotificationsModule} from '../shared/notifications/notifications.module';
import {AuthenticationPaymentGuard} from './services/auth/authentication-payment.guard';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {OrdersModule} from '../shared/orders/orders.module';

@NgModule({
  declarations: [
    NavigationComponent,
    ModalContentComponent,
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationPaymentGuard,
    AuthenticationService,
    StorageService,
    StorageRepository,
    SubscriptionsService,
    ModalService,
    AclService,
    RolesGuard,
  ],
  exports: [
    NavigationComponent,
    NavigationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SidenavModule,
    CardsModule,
    NavbarModule,
    AccordionModule,
    IconsModule,
    WavesModule,
    RouterModule,
    NotificationsModule,
    DropdownModule.forRoot(),
    OrdersModule,
  ]
})
export class CoreModule {
}
