import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-wiwn-maraton',
  templateUrl: './wiwn-maraton.component.html',
  styleUrls: ['./wiwn-maraton.component.scss']
})
export class WiwnMaratonComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
