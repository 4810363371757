import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PackagesRepository} from './packages.repository';
import {
  BonusModel, MainPackagesCollection,
  OrderModel,
  PackageBonusInput,
  PackageModel,
  PackagesCollection,
  PaymentStatusModel
} from './packages.interface';
import {UserRegisterData} from '../../../../../core/services/auth/authentication.model';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {

  constructor(
    private repository: PackagesRepository
  ) {
  }

  public getAll(): Observable<PackagesCollection> {
    return this.repository.findAll();
  }

  public getAllMain(): Observable<MainPackagesCollection> {
    return this.repository.findAllMain();
  }

  public findOne(packageId: string, bonusCode?: string, amount: number = 1): Observable<PackageModel> {
    return this.repository.findOne(packageId, bonusCode, amount);
  }

  public findOneForLogged(packageId: string, amount: number = 1): Observable<PackageModel> {
    return this.repository.findOneForLogged(packageId, amount);
  }

  public order(packageToOrder: PackageModel, bonusCode: string, invoice: any): Observable<OrderModel> {
    return this.repository.order({
      packageId: packageToOrder.id,
      code: bonusCode,
      invoiceContractor: invoice
    });
  }

  public orderForLogged(packageToOrder: PackageModel, invoice: any, amount: number = 1): Observable<OrderModel> {
    return this.repository.orderForLogged({
      packageId: packageToOrder.id,
      invoiceContractor: invoice,
      code: packageToOrder.promotion.code,
      amount
    });
  }

  public orderWithoutLogin(
    packageToOrder: PackageModel,
    bonusCode: string,
    invoice: any,
    userRegisterData: UserRegisterData,
    amount: number = 1,
  ): Observable<OrderModel> {
    return this.repository.orderWithoutRegister({
      packageId: packageToOrder.id,
      code: bonusCode,
      invoiceContractor: invoice,
      registerData: userRegisterData,
      amount
    });
  }

  public paymentStatus(orderModel: OrderModel): Observable<PaymentStatusModel> {
    return this.repository.status({
      transactionToken: orderModel.data.transactionToken
    });
  }

  public bonus(bonus: PackageBonusInput): Observable<BonusModel> {
    return this.repository.bonus({
      packageId: bonus.packageId,
      code: bonus.code
    });
  }
}
