import { Injectable } from '@angular/core';
import {TrackingParameters} from './tracker.interface';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  constructor() { }

  static event(name: string, params: TrackingParameters) {
    gtag('event', name, params);
  }

  static navigationUrlClicked(params: TrackingParameters) {
    gtag('event', 'menu_link_clicked', params);
  }

  static dashboardUrlClicked(params: TrackingParameters) {
    gtag('event', 'dashboard_link_clicked', params);
  }

  static signUp(method: string) {
    gtag('event', 'sign_up', {
      method
    });
  }
}
