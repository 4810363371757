import {Injectable} from '@angular/core';
import {EditableUserSettings, UserSettingsModel} from '../user-settings.interface';
import {environment} from '../../../../../../../environments/environment';

@Injectable()
export class UserSettingsMapper {
  public static mapModel(data: any): UserSettingsModel {
    return {
      data: {
        newLeadNotification: data.data.notification.newLead.enabled == 'true',
        newLeadNotificationBeforeTime: {
          hour: data.data.notification.newLead.beforeTime ? data.data.notification.newLead.beforeTime.hour : 22,
          minute: data.data.notification.newLead.beforeTime ? data.data.notification.newLead.beforeTime.minute : 0
        },
        newLeadNotificationAfterTime: {
          hour: data.data.notification.newLead.afterTime ? data.data.notification.newLead.afterTime.hour : 8,
          minute: data.data.notification.newLead.afterTime ? data.data.notification.newLead.afterTime.minute : 0
        },
        newLeadNotificationFrequency: data.data.notification.newLead.frequency ? +data.data.notification.newLead.frequency : 0,
        newLeadNotificationAllDay: data.data.notification.newLead.allDay === 'true',
        meta: {
          role: UserSettingsMapper.getUserRole(data),
          firstLogin: data.data.meta.firstLogin,
          activePoll: data.data.meta.activePoll,
          newsletter: data.data.meta.newsletter,
          hadNewsletter: data.data.meta.had_newsletter
        },
        preferences: {
          liteVersion: data.data?.liteVersion?.enabled == 'true'
        }
      }
    };
  }

  public mapToEditableModel(data: UserSettingsModel, password: string = null): EditableUserSettings {
    return {
      newLeadNotification: data.data.newLeadNotification,
      newLeadNotificationAfterTime: data.data.newLeadNotificationAfterTime,
      newLeadNotificationAllDay: data.data.newLeadNotificationAllDay,
      newLeadNotificationBeforeTime: data.data.newLeadNotificationBeforeTime,
      newLeadNotificationFrequency: data.data.newLeadNotificationFrequency,
      password: password
    };
  };

  private static getUserRole(ToMapData: any) {
    if (environment.packages.user.default.active === true && environment.packages.user.default.level > ToMapData.data.meta.role.level) {
      return {
        slug: environment.packages.user.default.slug,
        name: environment.packages.user.default.slug,
        daysLeft: 0,
        level: environment.packages.user.default.level,
        maxMarkets: environment.packages.user.default.maxMarkets,
        markets: ToMapData.data.meta.role.markets
      };
    }

    return {
      slug: ToMapData.data.meta.role.slug,
      name: ToMapData.data.meta.role.name,
      daysLeft: ToMapData.data.meta.role.daysLeft,
      level: ToMapData.data.meta.role.level,
      maxMarkets: ToMapData.data.meta.role.maxMarkets,
      markets: ToMapData.data.meta.role.markets
    };
  }
}
