import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {SidenavComponent} from 'ng-uikit-pro-standard';
import {AclService} from '../services/auth/acl/acl..service';
import {NotificationService} from '../../shared/notifications/services/notification.service';
import {StorageService} from '../services/storage/storage.service';
import {PackagesService} from '../../shared/orders/packages/services/trensactions/packages.service';
import {PackageModel} from '../../shared/orders/packages/services/trensactions/packages.interface';
import {TrackerService} from '../../shared/tracker/tracker.service';
import {UserSettingsStoreService} from '../../shared/user-settings/store/user-settings-store.service';
import {AuthStoreService} from '../services/auth/store/auth-store.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @ViewChild('sidenav', {static: true}) sidenav: SidenavComponent;

  public radioModel: string = 'dashboard';
  public isSellOrRent: string = null;
  public mobileMenuShow: boolean = false;
  public environment: any = {};

  packageId = 'inwestor_360';

  package: PackageModel = null;

  constructor(
    public authStore: AuthStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public acl: AclService,
    public notification: NotificationService,
    private storageService: StorageService,
    private packagesService: PackagesService,
    public userSettingsStore: UserSettingsStoreService,
  ) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.setTabActive();
    this.notification.fetchNotification();
    this.setPromotionPackage();
  }

  setPromotionPackage() {
    this.packagesService.findOneForLogged(this.packageId).subscribe((packageModel) => {
      this.package = packageModel;
    });
  }

  public getCountLastAddedLeadsToMarketResearch(): string[] {
    if (this.storageService.getSingle('last_saved_leads') === null) {
      return [];
    }

    return JSON.parse(this.storageService.getSingle('last_saved_leads'));
  }

  public onPageChange(navigateTo: string) {
    TrackerService.navigationUrlClicked({
      event_category: 'panel_navigation',
      event_label: navigateTo
    });

    this.router.navigate([navigateTo], {replaceUrl: true});

    this.mobileMenuShow = false;
    document.body.classList.remove('overflow-hidden');
    this.setTabActive();
  }

  private setTabActive() {
    if (typeof this.activatedRoute.snapshot.url.slice(-1)[0] !== 'undefined') {
      this.radioModel = this.activatedRoute.snapshot.url.slice(-1)[0].path;

      this.isSellOrRent = this.activatedRoute.snapshot.url.slice(-2)[0].path;

      if (this.isSellOrRent === 'rent' || this.isSellOrRent === 'sell') {
        this.radioModel = this.isSellOrRent + '-' + this.radioModel;
      }

    }
  }

  showSideNav() {
    this.mobileMenuShow = true;
    document.body.classList.add('overflow-hidden');
    this.sidenav.show();
  }

  hideMobileMenu() {
    this.mobileMenuShow = false;
    document.body.classList.remove('overflow-hidden');
    this.sidenav.hide();
  }
}
