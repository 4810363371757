import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TranslateModule} from '@ngx-translate/core';
import {CoreModule} from '../core/core.module';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from '../core/services/auth/authentication.guard';
import {AuthComponent} from './auth/auth.component';
import {RolesGuard} from '../core/services/auth/acl/roles.guard';
import {UserSettingsModule} from '../shared/user-settings/user-settings.module';
import {RedirectionComponent} from './redirection/redirection.component';
import {LeftSideContentComponent} from './left-side-content/left-side-content.component';
import {EmptyComponent} from './empty/empty.component';
import {AuthenticationPaymentGuard} from '../core/services/auth/authentication-payment.guard';
import {CenteredComponent} from './centered/centered.component';
import {WiwnMaratonComponent} from './wiwn-maraton/wiwn-maraton.component';
import {PerelkiComponent} from './perelki/perelki.component';
import {WiwnComponent} from './wiwn/wiwn.component';
import {WiwnLiteComponent} from './wiwn-lite/wiwn-lite.component';
import {PromotionBannerComponent} from './promotion-banner-view/promotion-banner.component';
import {BlackWeekComponent} from './black-week/black-week.component';
import {WiwnLiderComponent} from './wiwn-lider/wiwn-lider.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'maraton/:hash',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'welcome/:hash',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'first-steps/filters/:leadType',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/set-first-filters/set-first-filters.module').then(m => m.SetFirstFiltersModule)
  },
  {
    path: 'first-steps/notification/:leadType',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/set-first-notification/set-first-notification.module').then(m => m.SetFirstNotificationModule)
  },
  {
    path: 'first-steps/done/:leadType',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/first-steps-thank-you-page/first-steps-thank-you-page.module').then(m => m.FirstStepsThankYouPageModule)
  },
  {
    path: 'regulation/promotion/userByDay',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/regulation-user-by-day-promotion/regulation-user-by-day-promotion.module').then(m => m.RegulationUserByDayPromotionModule)
  },
  {
    path: 'questionnaire',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/poll-page/poll-page.module').then(m => m.PollPageModule)
  },
  {
    path: 'contact',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'special-offer/:token',
    component: RedirectionComponent,
    loadChildren: () => import('../pages/offer-redirection/offer-redirection.module').then(m => m.OfferRedirectionModule)
  },
  {
    path: 'lead/:lead',
    component: RedirectionComponent,
    loadChildren: () => import('../pages/lead-redirection/lead-redirection.module').then(m => m.LeadRedirectionModule)
  },
  {
    path: 'auction/:auction',
    component: RedirectionComponent,
    loadChildren: () => import('../pages/auction-redirection/auction-redirection.module').then(m => m.AuctionRedirectionModule)
  },
  {
    path: 'leads/sell',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/sell/flat',
    data: {
      leadType: 'flat',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/sell/house',
    data: {
      leadType: 'house',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/sell/land',
    data: {
      leadType: 'land',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/sell/warehouse',
    data: {
      leadType: 'warehouse',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/sell/garage',
    data: {
      leadType: 'garage',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/sell/premises',
    data: {
      leadType: 'premises',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/sell-page/sell-page.module').then(m => m.SellPageModule)
  },
  {
    path: 'leads/rent/flat',
    data: {
      leadType: 'flatRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },

  {
    path: 'leads/rent/house',
    data: {
      leadType: 'houseRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },
  {
    path: 'leads/rent/land',
    data: {
      leadType: 'landRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },
  {
    path: 'leads/rent/warehouse',
    data: {
      leadType: 'warehouseRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },
  {
    path: 'leads/rent/garage',
    data: {
      leadType: 'garageRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },
  {
    path: 'leads/rent/premises',
    data: {
      leadType: 'premisesRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },
  {
    path: 'leads/rent/room',
    data: {
      leadType: 'roomRent',
    },
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/rent-page/rent-page.module').then(m => m.RentPageModule)
  },



  {
    path: 'auctions-flats',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/leads/auctions-flats-page/auctions-flats-page.module').then(m => m.AuctionsFlatsPageModule)
  },
  {
    path: 'market-research',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/market-research/market-research-page.module').then(m => m.MarketResearchPageModule)
  },
  {
    path: 'market-research/:savedLeadId',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/market-research/market-research-page.module').then(m => m.MarketResearchPageModule)
  },
  {
    path: 'stats',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/statistics/statistics-page.module').then(m => m.StatisticsPageModule)
  },

  {
    path: 'black-week/pakiet-podstawowy',
    component: EmptyComponent,
    data: { packageid: '2cdf388a-ec2e-4c03-8362-df043ac253db'},
    loadChildren: () => import('../pages/payments-without-login/components/black-week/black-week.module').then(m => m.BlackWeekModule)
  },

  {
    path: 'wiwn/inwestor_za_500',
    component: WiwnLiteComponent,
    data: {
      packageId: 'inwestor_360',
      bonusCode: 'inwestor_500',
    },
    loadChildren: () => import('../pages/payments-without-login/components/with-expiration/with-expiration.module').then(m => m.WithExpirationModule)
  },
  {
    path: 'wiwn/inwestor_za_200',
    component: WiwnLiteComponent,
    data: {
      packageId: 'inwestor_360',
      bonusCode: 'inwestor_200',
    },
    loadChildren: () => import('../pages/payments-without-login/components/with-expiration/with-expiration.module').then(m => m.WithExpirationModule)
  },
  {
    path: 'newsletter/confirmation',
    component: CenteredComponent,
    loadChildren: () => import('../pages/newsletter-confirmation/newsletter-confirmation.module').then(m => m.NewsletterConfirmationModule)
  },
  {
    path: 'black-week/pakiet-podstawowy',
    component: EmptyComponent,
    data: {
      packageId: '2cdf388a-ec2e-4c03-8362-df043ac253db',
      bonusCode: 'basic_365_full_price_75_percent_discount',
    },
    loadChildren: () => import('../pages/payments-without-login/components/black-week/black-week.module').then(m => m.BlackWeekModule)
  },
  {
    path: 'black-week/pakiet-inwestor',
    component: EmptyComponent,
    data: {
      packageId: 'inwestor_360',
      bonusCode: 'inwestor_365_full_price_35_percent_discount',
    },
    loadChildren: () => import('../pages/payments-without-login/components/black-week/black-week.module').then(m => m.BlackWeekModule)
  },
  {
    path: 'order/minus_1000_na_wakacje',
    component: PromotionBannerComponent,
    data: {
      packageId: 'inwestor_360',
      bonusCode: 'minus_1000_na_wakacje',
    },
    loadChildren: () => import('../pages/payments-without-login/components/with-expiration/with-expiration.module').then(m => m.WithExpirationModule)
  },
  {
    path: 'order/podstawowy/360',
    component: EmptyComponent,
    data: {
      packageId: '2cdf388a-ec2e-4c03-8362-df043ac253db',
      bonusCode: 'basic_365_full_price_65_percent_discount',
    },
    loadChildren: () => import('../pages/payments-without-login/components/standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'order',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/payments-without-login.module').then(m => m.PaymentsWithoutLoginModule)
  },
  {
    path: 'order/plans/:packageId/:amount',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/components/standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'order/plan/:packageId',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/components/standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'order/plan/:packageId/:bonusCode',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/components/standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'order/package/:packageId',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/payments-without-login.module').then(m => m.PaymentsWithoutLoginModule)
  },
  {
    path: 'order/:status',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/payments-without-login.module').then(m => m.PaymentsWithoutLoginModule)
  },
  {
    path: 'order/:status/:firstLoginToken',
    component: EmptyComponent,
    loadChildren: () => import('../pages/payments-without-login/payments-without-login.module').then(m => m.PaymentsWithoutLoginModule)
  },
  {
    path: 'payments',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'payments/transactions',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: 'payments/packages',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/packages/packages.module').then(m => m.PackagesModule)
  },
  {
    path: 'payments/unavailable/:unavailableSite',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'payments/package/:packageId/:bonusCode',
    component: DashboardComponent,
    canActivate: [AuthenticationPaymentGuard],
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'payments/package/:packageId',
    component: DashboardComponent,
    canActivate: [AuthenticationPaymentGuard],
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'payments/:status',
    component: DashboardComponent,
    canActivate: [AuthenticationPaymentGuard],
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'payments/:status/:firstLoginToken',
    component: DashboardComponent,
    canActivate: [AuthenticationPaymentGuard],
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'settings',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'settings/logout',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'settings/notifications',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, RolesGuard],
    loadChildren: () => import('../pages/notifications/notifications-page.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'login/token/:firstLoginToken',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login-by-token/login-by-token.module').then(m => m.LoginByTokenModule)
  },
  {
    path: 'register/token/:firstLoginToken',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login-by-token/login-by-token.module').then(m => m.LoginByTokenModule)
  },
  {
    path: 'register/success/:firstLoginToken',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login-by-token/login-by-token.module').then(m => m.LoginByTokenModule)
  },
  {
    path: 'login',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login/:redirect',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login/:redirect/:redirectParam1',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login/:redirect/:redirectParam1/:redirectParam2',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/login/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'register-maraton',
    component: WiwnMaratonComponent,
    loadChildren: () => import('../pages/auth/register-maraton-wiwn/register-maraton-wiwn.module').then(m => m.RegisterMaratonWiwnModule)
  },
  {
    path: 'register-lider',
    component: WiwnLiderComponent,
    loadChildren: () => import('../pages/auth/register-maraton-wiwn/register-maraton-wiwn.module').then(m => m.RegisterMaratonWiwnModule)
  },
  {
    path: 'register-black-week',
    component: BlackWeekComponent,
    loadChildren: () => import('../pages/auth/register-black-friday/register-black-friday.module').then(m => m.RegisterBlackFridayModule)
  },
  {
    path: 'register-wiwn',
    component: WiwnComponent,
    loadChildren: () => import('../pages/auth/register-wiwn/register-wiwn.module').then(m => m.RegisterWiwnModule)
  },
  {
    path: 'register-perelki',
    component: PerelkiComponent,
    loadChildren: () => import('../pages/auth/register-perelki/register-perelki.module').then(m => m.RegisterPerelkiModule)
  },
  {
    path: 'register/success',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password/:reset-token',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'set-password/:reset-token',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/set-password/set-password.module').then(m => m.SetPasswordModule)
  },
  {
    path: 'activate/:activation-token',
    component: AuthComponent,
    loadChildren: () => import('../pages/auth/activated/activated.module').then(m => m.ActivatedModule)
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    AuthComponent,
    RedirectionComponent,
    LeftSideContentComponent,
    EmptyComponent,
    CenteredComponent,
    WiwnMaratonComponent,
    WiwnLiderComponent,
    PerelkiComponent,
    WiwnComponent,
    WiwnLiteComponent,
    PromotionBannerComponent,
    BlackWeekComponent
  ],
  exports: [
    DashboardComponent,
    AuthComponent,
    WiwnMaratonComponent,
    WiwnLiderComponent,
    PerelkiComponent,
    WiwnComponent,
    WiwnLiteComponent,
    PromotionBannerComponent,
    BlackWeekComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    RouterModule.forChild(routes),
    UserSettingsModule
  ]
})
export class LayoutsModule {
}
