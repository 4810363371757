import { Component, OnInit } from '@angular/core';
import {UserSettingsStoreService} from "../../shared/user-settings/store/user-settings-store.service";
import {UserSettingsService} from "../../shared/user-settings/services/user-settings/user-settings.service";
import {SubscriptionsService} from "../../core/services/subscriptions/subscriptions.service";
import {AuthenticationService} from "../../core/services/auth/authentication.service";
import {User} from "../../core/services/auth/authentication.model";
import {UserSettingsModel} from "../../shared/user-settings/services/user-settings/user-settings.interface";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public hashInfo = null;

  constructor(
    public userSettingsStore: UserSettingsStoreService,
    private userSettings: UserSettingsService,
    private subscriptionService: SubscriptionsService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('hash') === 'wiwn') {
      this.hashInfo = 'Dziękujemy za zaufanie! Pamiętaj by aktywować swoje konto poprzez link wysłany na maila w ciągu 24h. W przeciwnym razie nie będziesz mógł ponownie się zalogować.';
    }

    if (this.userSettingsStore.userSettingsSubject.getValue() === null) {
      this.fetchUserSettings();
    } else {
      this.checkUserRole(this.userSettingsStore.userSettingsSubject.getValue());
    }
  }

  public fetchUserSettings() {
    this.subscriptionService.create('user-settings-get-dashboard', this.userSettings.getAll().subscribe(userSettings => {
      this.userSettingsStore.userSettingsSubject.next(userSettings);
      this.checkUserRole(userSettings);
    }));
  }

  private checkUserRole(userSettings: UserSettingsModel) {
    if (!this.authenticationService.user.getValue().roles.includes(userSettings.data.meta.role.slug)) {
      let user: User = this.authenticationService.user.getValue();
      user.roles = [userSettings.data.meta.role.slug];

      this.authenticationService.setCredentials(user, false);
      this.authenticationService.applyUser(user);
    }
  }
}
