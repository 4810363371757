import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {AuthenticationService} from "../authentication.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AclService {
  constructor(private authService: AuthenticationService) {}

  public hasAccessToUrl(url?: string): boolean {
    if (null === url) {
      return true;
    }

    const pathPermisions = this.getPathPermissions();
    for (const knownUrl in pathPermisions) {
      if (knownUrl != '/' && url.startsWith(knownUrl)) {
        url = knownUrl;
      }
    }

    if (!(url in pathPermisions)) {
      return false;
    }

    const allowedRoles = pathPermisions[url];
    return this.authService.hasRequiredRoles(allowedRoles);
  }

  private getPathPermissions() {
    return environment.acl.path;
  }
}
