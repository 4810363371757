// LIBS
import {Injectable} from '@angular/core';

// INTERFACE
import {
    StorageCreateInput,
    StorageDeleteInput,
    StorageFindInput,
    StorageUpdateInput
} from './storage.interface';

@Injectable()
export class StorageRepository {
    private storage: any;
    private defaultDriver: Storage = localStorage;

    constructor() {
        this.storage = this.defaultDriver;
    }

    changeDriver(newDriver: Storage): void {
        if (newDriver) {
            this.storage = newDriver;
        }
    }

    create(params: StorageCreateInput): void {
        this.storage.setItem(
            params.key,
            params.value
        );
    }

    find(params: StorageFindInput): any {
        return this.storage.getItem(
            params.key
        );
    }

    delete(params: StorageDeleteInput): any {
        return this.storage.removeItem(
            params.key
        );
    }

    deleteAll(): void {
        return this.storage.clear();
    }

    findAll(): { [s: string]: string } {
        const items = {};
        for (let i = 0, len = this.storage.length; i < len; ++i) {
            items[this.storage.key(i)] = (this.storage.getItem(this.storage.key(i)));
        }
        return items;
    }

    update(params: StorageUpdateInput) {
        this.delete(
            {key: params.key}
        );
        this.create({
            key: params.key,
            value: params.value
        });
    }
}
