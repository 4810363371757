import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-black-week',
  templateUrl: './black-week.component.html',
  styleUrls: ['./black-week.component.scss']
})
export class BlackWeekComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
