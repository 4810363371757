import {Injectable} from '@angular/core';
import {TransactionModel} from "../transactions.interface";

@Injectable()
export class TransactionsMapper {
  public static mapModel(data: any): TransactionModel {
    return {
      created_at: data.created_at,
      id: data.id,
      package_currency: data.package_currency,
      package_id: data.package_id,
      package_name: data.package_name,
      package_price: data.package_price,
      package_profit: data.package_profit,
      package_profit_unit: data.package_profit_unit,
      status: data.status
    };
  }

  public mapCollection(data: any): TransactionModel[] {
    if (data.data.transactions === undefined) {
      return [];
    }

    return data.data.transactions.map(resp => {
      return TransactionsMapper.mapModel(resp);
    });
  }
}
