// LIBS
import {Injectable} from '@angular/core';

// INTERFACE
import {StorageCreateInput} from './storage.interface';

// REPOSITORY
import {StorageRepository} from './storage.repository';

@Injectable()
export class StorageService {
    constructor(
        private storageRepository: StorageRepository
    ) {
    }

    /**
     * Set single element from storage by driver
     *
     * @param {StorageCreateInput} element
     * @param {Storage} driver
     */
    setSingle(element: StorageCreateInput, driver?: Storage): void {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        this.storageRepository.create({
            key: element.key,
            value: element.value
        });
    }

    /**
     * Get single element from storage by driver
     *
     * @param {string} elementKey
     * @param {Storage} driver
     */
    getSingle(elementKey: string, driver?: Storage): any {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        return this.storageRepository.find({
            key: elementKey
        });
    }

    /**
     * Delete single element from storage by driver
     *
     * @param {string} elementKey
     * @param {Storage} driver
     */
    deleteSingle(elementKey: string, driver?: Storage): void {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        this.storageRepository.delete({
            key: elementKey
        });
    }

    /**
     * Set element in multiple record (json) in storage
     *
     * @param {string} key
     * @param {StorageCreateInput} element
     * @param {Storage} driver
     */
    setMultiple(key: string, element: StorageCreateInput, driver?: Storage): void {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        const currentVal = this.storageRepository.find({
            key: key
        });
        if (currentVal) {
            const currentValObj = JSON.parse(currentVal);
            currentValObj[element.key] = element.value;
            this.storageRepository.update({
                key: key,
                value: JSON.stringify(currentValObj)
            });
        } else {
            const toSave = {};
            const toSaveKey = element.key;
            toSave[toSaveKey] = element.value;
            this.storageRepository.create({
                key: key,
                value: JSON.stringify(toSave)
            });
        }
    }

    /**
     * Get one element from multiple record (json) in storage
     *
     * @param {string} key
     * @param {string} elementKey
     * @param {Storage} driver
     */
    getOneFromMultiple(key: string, elementKey: string, driver?: Storage): any {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        const currentVal = this.storageRepository.find({
            key: key
        });
        if (currentVal) {
            const currentValObj = JSON.parse(currentVal);
            if (currentValObj && currentValObj[elementKey]) {
                return currentValObj[elementKey];
            }
        }
        return false;
    }

    /**
     * Get all elements from multiple record (json) in storage
     *
     * @param {string} key
     * @param {Storage} driver
     */
    getAllFromMultiple(key: string, driver?: Storage): Object | boolean {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        const currentVal = this.storageRepository.find({
            key: key
        });
        if (currentVal) {
            const currentValObj = JSON.parse(currentVal);
            if (currentValObj) {
                return currentValObj;
            }
        }
        return false;
    }

    /**
     * Delete element from multiple record (json) in storage
     *
     * @param {string} key
     * @param {string} elementKey
     * @param {Storage} driver
     */
    deleteMultiple(key: string, elementKey: string, driver?: Storage): void {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        const currentVal = this.storageRepository.find({
            key: key
        });
        if (currentVal) {
            const currentValObj = JSON.parse(currentVal);
            if (currentValObj && currentValObj[elementKey]) {
                delete currentValObj[elementKey];
                this.storageRepository.update({
                    key: key,
                    value: JSON.stringify(currentValObj)
                });
            }
        }
    }

    /**
     * Delete all records from provided storage driver or from default driver
     *
     * @param {Storage} driver
     */
    deleteAll(driver?: Storage): void {
        if (driver) {
            this.storageRepository.changeDriver(driver);
        }
        this.storageRepository.deleteAll();
    }

    /**
     * Change the storage driver to keep data there (localStorage / sessionStorage)
     *
     * @param {Storage} driver
     */
    changeStorageDriver(driver: Storage): void {
        if (!driver) {
            return;
        }
        this.storageRepository.changeDriver(
            driver
        );
    }

    /**
     * Delete all values from local storage except provided ones.
     *
     * @param {string[]} keys
     */
    deleteAllExcept(keys: string[]): void {
        Object.keys(this.storageRepository.findAll()).forEach((key) => {
            if (keys.indexOf(key) < 0) {
                this.storageRepository.delete({key: key});
            }
        });

    }

}
