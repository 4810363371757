import { Injectable } from '@angular/core';
import {NotificationRepository} from "./notification.repository";
import {Observable} from "rxjs";
import {NotificationModel} from "./notification.interface";
import {NotificationStoreService} from "../store/notification-store.service";
import {SubscriptionsService} from "../../../core/services/subscriptions/subscriptions.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private repository: NotificationRepository,
    private store: NotificationStoreService,
    private subscription: SubscriptionsService
  ) { }

  public getAll(): Observable<NotificationModel[]> {
    return this.store.notifications$;
  }

  public getAllFiltersIds(): string[] {
    const filters = this.store.notificationsSubject.getValue();

    if (null === filters) {
      return [];
    }

    return filters.map((notification) => {
      return notification.filterId;
    });
  }

  public getAllMarketIds(): string[] {
    const markets = this.store.notificationsSubject.getValue();

    if (null === markets) {
      return [];
    }

    return markets.map((notification) => {
      return notification.marketId;
    });
  }

  public save(notification: NotificationModel): Observable<any> {
    return this.repository.save(notification);
  }

  public delete(notification: NotificationModel): Observable<any> {
    return this.repository.delete(notification);
  }

  public clearAndFetchNotifications(): void {
    this.subscription.create('notifications-get-all', this.repository.findAll().subscribe(notifications => {
      this.store.notificationsSubject.next(notifications);
    }));
  }
  public fetchNotification(): void
  {
    if (this.store.notificationsSubject.getValue() !== null) {
      return;
    }

    this.subscription.create('notifications-get-all', this.repository.findAll().subscribe(notifications => {
      this.store.notificationsSubject.next(notifications);
    }));
  }
}
