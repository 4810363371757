<section class="light-podkluczyk-skin body h-100">
  <div class="container-fluid">
    <div class="row no-gutter">
      <!-- The content half -->
      <div class="col-md-12 bg-primary">
        <div class="login d-flex justify-content-center py-5">

          <div>
            <h3 class="display-4"><img src="assets/images/logo-name.png"></h3>
            <p class="text-muted mb-4">{{ 'Your investment assistant' | translate }}</p>
            <router-outlet name="main"></router-outlet>
          </div>

        </div>
      </div><!-- End -->

    </div>
  </div>

</section>
