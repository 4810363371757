import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http';
import {StorageService} from '../storage/storage.service';
import {retryWhen, mergeMap, delay, take, catchError} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
  ) {
  }

  post(method: string, params: { [s: string]: string|number|boolean|object }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken()
      })
    };
    return this.httpClient.post<any>(
      this.getBaseApiUrl() + '/' + method, params, httpOptions
    ).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error) => {
            switch (error.status) {
              case 403:
                throwError(error);
                break;
              case 401:
                this.clearLocalStorage();
                break;
            }
            return throwError(error);
          }),
          delay(1000),
          take(2)
        );
      })
    );
  }

  postObject<T>(method: string, obj: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    };
    return this.httpClient.post<T>(
      this.getBaseApiUrl() + '/' + method, obj, httpOptions
    ).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error) => {
            switch (error.status) {
              case 403:
                throwError(error);
                break;
              case 401:
                this.clearLocalStorage();
                break;
            }
            return throwError(error);
          }),
          delay(1000),
          take(2)
        );
      })
    );
  }

  put(method: string, params: { [s: string]: string|number|boolean|object }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken()
      })
    };
    return this.httpClient.put<any>(
      this.getBaseApiUrl() + '/' + method, params, httpOptions
    ).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error) => {
            switch (error.status) {
              case 403:
                throwError(error);
                break;
              case 401:
                this.clearLocalStorage();
                break;
            }
            return throwError(error);
          }),
          delay(1000),
          take(2)
        );
      })
    );
  }

  get(method: string, params: { [s: string]: string }) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken()
      }),
      params
    };
    return this.httpClient.get<any>(
      this.getBaseApiUrl() + '/' + method, httpOptions
    ).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error: HttpErrorResponse) => {
            throw of(error);
          }),
          delay(500),
          take(2)
        );
      })
    );
  }

  delete(method: string, params: { [s: string]: string }, api: string = 'community'): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken()
      })
    };
    return this.httpClient.delete<any>(
      this.getBaseApiUrl() + '/' + method, {headers: httpOptions.headers, params}
    );
  }

  private clearLocalStorage() {
    this.storageService.deleteSingle(environment.api.community.key, localStorage);
    this.storageService.deleteSingle('credentials', localStorage);
    location.reload();
  }

  private getToken(): string {
    return this.storageService.getSingle(environment.api.community.key, sessionStorage) ||
      this.storageService.getSingle(environment.api.community.key, localStorage);
  }

  public getBaseApiUrl() {
    return environment.api.community.baseUrl + '/api';
  }
}
